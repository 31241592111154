import React, { Suspense } from 'react'
import PageLoader from '../PageLoader'

const Loadable = (Component) => (props) => {

  const WrappedComponent = () => {
    return (
      <Suspense fallback={<PageLoader />}>
        <Component {...props} />
      </Suspense>
    )
  }

  return <WrappedComponent />
}

export default Loadable