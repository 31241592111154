import React from 'react'
import Lottie from 'react-lottie';
import styles from './PageLoader.module.sass'
import loaderData from '../../data/loader.json'

const PageLoader = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loaderData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={styles.pageLoader}>
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  )
}

export default PageLoader