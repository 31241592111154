import React, { useEffect, useState } from 'react'
import cn from "classnames"
import styles from './CustomCursor.module.sass'

const CustomCursor = ({sectionInitalVisible}) => {

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [isInViewSection, setIsInViewSection] = useState(false);

  useEffect(() => {
    const onMouseMove = (e) => {
      /* setPosition({ x: (e.clientX - 20), y: (e.clientY - 20) });
      setTimeout(() => { */
        setPosition({ x: e.clientX, y: e.clientY });
      /* }, 100) */
    };

    const onMouseEnter = () => {
      setIsVisible(true);
    };

    const onMouseLeave = () => {
      setIsVisible(false);
    };

    const onSectionEnter = () => {
      setIsInViewSection(true);
    };

    const onSectionLeave = () => {
      setIsInViewSection(false);
    };
    
  
    const developmentSection = document.querySelector("#viewCursorDevelopment") // service page id base query select
    const supportSection = document.querySelector("#viewCursorSupport")  // service page id base query select
    const homeSupportSection = document.querySelector("#viewCursorHomeSupport")  // Home page id base query select
    const homeSevelopmentSection = document.querySelector("#viewCursorHomeDevelopment") // Home page id base query select


    const section = document.querySelector('.viewcursor');
    // console.log('Custom Cursor Sec =>', section);
    if(section) {
      section.addEventListener('mouseenter', onSectionEnter);
      section.addEventListener('mouseleave', onSectionLeave);
    }
    if(developmentSection){
      developmentSection.addEventListener('mouseenter', onSectionEnter);
      developmentSection.addEventListener('mouseleave', onSectionLeave);
    }
    if(supportSection){
      supportSection.addEventListener('mouseenter', onSectionEnter);
      supportSection.addEventListener('mouseleave', onSectionLeave);
    }
    if(homeSupportSection){
      homeSupportSection.addEventListener('mouseenter', onSectionEnter);
      homeSupportSection.addEventListener('mouseleave', onSectionLeave);
    }
    if(homeSevelopmentSection){
      homeSevelopmentSection.addEventListener('mouseenter', onSectionEnter);
      homeSevelopmentSection.addEventListener('mouseleave', onSectionLeave);
    }
    
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseenter', onMouseEnter);
    document.addEventListener('mouseleave', onMouseLeave);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseenter', onMouseEnter);
      document.removeEventListener('mouseleave', onMouseLeave);
      if(section) {
        section.removeEventListener('mouseenter', onSectionEnter);
        section.removeEventListener('mouseleave', onSectionLeave);
      }
      if(developmentSection){
        developmentSection.addEventListener('mouseenter', onSectionEnter);
        developmentSection.addEventListener('mouseleave', onSectionLeave);
      }
      if(supportSection){
        supportSection.addEventListener('mouseenter', onSectionEnter);
        supportSection.addEventListener('mouseleave', onSectionLeave);
      }
      if(homeSupportSection){
        homeSupportSection.addEventListener('mouseenter', onSectionEnter);
        homeSupportSection.addEventListener('mouseleave', onSectionLeave);
      }
      if(homeSevelopmentSection){
        homeSevelopmentSection.addEventListener('mouseenter', onSectionEnter);
        homeSevelopmentSection.addEventListener('mouseleave', onSectionLeave);
      }
    };
  }, [position]);

  // Set isInViewSection to false initially when the component renders
  useEffect(() => {
    // console.log('Render', sectionInitalVisible);
    setIsInViewSection(false); // Set isInViewSection to false when component mounts
  }, [sectionInitalVisible]);

  return (
    <div className={cn(styles.customCursor, isVisible ? styles.cursorVisible : '', isInViewSection ? styles.textVisible : '')} style={{ translate: 'none', rotate: 'none', scale: 'none', transform: `translate3d(${position.x}px, ${position.y}px, 0)` }}>
      <div className={styles.cursorText}>View</div>
    </div>
  )
}

export default CustomCursor