import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import MobileMenu from "../MobileMenu";
import CustomCursor from "../CustomCursor";

const Page = ({ children, containerClass, overflowHidden }) => {
    const { pathname } = useLocation();
    const [initalVisible, setInitialVisible] = useState(pathname);
    useEffect(() => {
        window.scrollTo(0, 0);
        clearAllBodyScrollLocks();
        setInitialVisible(pathname);
        // console.log('Pathname =>', pathname)
    }, [pathname]);

    return (
        <div className={styles.main}>
            <Header containerClass={containerClass} />
            <div className={cn(styles.inner, !overflowHidden ? styles.overflowHidden : '')}>{children}</div>
            <MobileMenu />
            <Footer containerClass={containerClass} />
            <CustomCursor sectionInitalVisible={initalVisible} />
        </div>
    );
};

export default Page;
