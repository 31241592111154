import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./Subscription.module.sass";
import Icon from "../Icon";

const Subscription = ({ className, placeholder }) => {
 
  //Mailchimp Function
  useEffect(() => {
    // Load Mailchimp validation script
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.async = true;
    document.body.appendChild(script);

    // Initialize jQuery for Mailchimp form
    if (window.jQuery) {
      window.jQuery(function ($) {
        window.fnames = ['EMAIL', 'FNAME', 'PHONE', 'LNAME', 'ADDRESS', 'BIRTHDAY'];
        window.ftypes = ['email', 'text', 'phone', 'text', 'address', 'birthday'];
      });
    }
    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <form
        className={cn(styles.form, className, 'validate')}
        action="https://uitoux.us10.list-manage.com/subscribe/post?u=3d76b956990407b7051ae1517&amp;id=39f2f0beb8&amp;f_id=00ffefe5f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        //target="_blank"
      >
        <div className={cn(styles.formGroup)}>
          <input
            className={styles.input}
            type="email"
            //value={email}
            //onChange={(e) => setEmail(e.target.value)}
            name="EMAIL"
            id="mce-EMAIL"
            placeholder={placeholder}
            required
          />
          <button type="submit" className={styles.btn} name="subscribe" id="mc-embedded-subscribe">
            <Icon name="arrow-right" size="14" />
          </button>
        </div>
        <div hidden=""><input type="hidden" name="tags" value="14265692" /></div>
        <div id="mce-responses" className="clear foot">
          <div className={styles.errorResponse} id="mce-error-response" style={{display: 'none'}}></div>
          <div className={styles.successResponse} id="mce-success-response" style={{display: 'none'}}></div>
        </div>
        <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <input type="text" name="b_3d76b956990407b7051ae1517_39f2f0beb8" tabIndex="-1" />
        </div>
      </form>
    </div>
  );
};

export default Subscription;
