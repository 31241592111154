import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
    {
        title: "Services",
        url: "/services",
    },
    {
        title: "Case Studies",
        url: "/case-studies",
    },
    {
        title: "Blogs",
        url: "/blogs",
    },
    {
        title: "Careers",
        url: "/careers",
    },
    {
        title: "About Us",
        url: "/about-us",
    }
];

const socials = [
    {
        title: "facebook",
        size: "16",
        url: "https://www.facebook.com/ui8.net/",
    },
    {
        title: "twitter",
        size: "18",
        url: "https://twitter.com/ui8",
    },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/ui8net/",
    },
    {
        title: "dribbble",
        size: "16",
        url: "https://dribbble.com/ui8",
    },
    {
        title: "behance",
        size: "20",
        url: "https://www.behance.net/ui8",
    },
];

const Footer = ({containerClass}) => {
    const [visible, setVisible] = useState(false);

    const { pathname } = useLocation();

    return (
        <footer className={styles.footer}>
            <div className={styles.body}>
                <div className={cn(containerClass ? containerClass : "container", styles.container)}>
                    <div className={styles.col}>
                        <div className={styles.box}>
                            <Link className={styles.logo} to="/">
                                <Image
                                    className={styles.pic}
                                    src="/images/logo-dark.svg"
                                    srcDark="/images/logo-light.svg"
                                    alt="Uitoux Solutions"
                                />
                            </Link>
                            <Theme className={styles.theme} />
                        </div>
                        <div
                            className={cn(styles.item, {
                                [styles.active]: visible,
                            })}
                        >
                            {/* <div
                                className={styles.category}
                                onClick={() => setVisible(!visible)}
                            >
                                footer nav
                                <Icon name="arrow-bottom" size="9" />
                            </div> */}
                            <div className={styles.menu}>
                                {menu.map((x, index) => (
                                    <NavLink
                                        className={cn(styles.link, {
                                            [styles.active]: pathname === x.url,
                                        })}
                                        to={x.url}
                                        key={index}
                                    >
                                        {x.title}
                                    </NavLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>contact</div>
                        <div className={styles.info}>
                            <p>1st Floor, No. 20, 147-A,</p>
                            <p>E Ponnurangam Rd, R.S. Puram,</p>
                            <p>Coimbatore, Tamil Nadu 641002</p>
                            {/* <p>732-528-4945</p> */}
                            <a href="mailto:hello@uitoux.in">hello@uitoux.in</a>
                            <a href="tel:+91 89252 97807">+91 89252 97807</a>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.category}>NEWSLETTER</div>
                        <div className={cn(styles.info, styles.maxWidth310)}>
                            Get our newsletter with inspiration on the latest trends, projects and much more.
                        </div>
                        {/* <Link className={styles.greenButton} to="/">Book an Appointment</Link> */}
                        <Subscription
                            className={styles.subscription}
                            placeholder="Enter your email"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2024 UITOUX. All rights reserved
                    </div>
                    {/* <div className={styles.socials}>
                        {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))}
                    </div> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
