import React from 'react'
import cn from "classnames"
import { NavLink, useLocation } from 'react-router-dom'
import styles from './MobileMenu.module.sass'
import Image from '../Image'

const navLinks = [
  {
    title: "Home",
    url: "/",
    image: '/images/content/mobile-menu/home/home.svg',
    imageDark: '/images/content/mobile-menu/home/home-dark.svg',
    imageActive: '/images/content/mobile-menu/home/home-active.svg',
    imageDarkActive: '/images/content/mobile-menu/home/home-active-dark.svg'
  },
  {
    title: "Services",
    url: "/services",
    image: '/images/content/mobile-menu/service/mobile.svg',
    imageDark: '/images/content/mobile-menu/service/mobile-dark.svg',
    imageActive: '/images/content/mobile-menu/service/mobile-active.svg',
    imageDarkActive: '/images/content/mobile-menu/service/mobile-active-dark.svg'
  },
  {
    title: "Case Studies",
    url: "/case-studies",
    image: '/images/content/mobile-menu/case-studies/book.svg',
    imageDark: '/images/content/mobile-menu/case-studies/book-dark.svg',
    imageActive: '/images/content/mobile-menu/case-studies/book-active.svg',
    imageDarkActive: '/images/content/mobile-menu/case-studies/book-active-dark.svg'
  },
  {
    title: "Careers",
    url: "/careers",
    image: '/images/content/mobile-menu/careers/briefcase.svg',
    imageDark: '/images/content/mobile-menu/careers/briefcase-dark.svg',
    imageActive: '/images/content/mobile-menu/careers/briefcase-active.svg',
    imageDarkActive: '/images/content/mobile-menu/careers/briefcase-active-dark.svg'
  },
  {
    title: "About Us",
    url: "/about-us",
    image: '/images/content/mobile-menu/about-us/info.svg',
    imageDark: '/images/content/mobile-menu/about-us/info-dark.svg',
    imageActive: '/images/content/mobile-menu/about-us/info-active.svg',
    imageDarkActive: '/images/content/mobile-menu/about-us/info-active-dark.svg'
  },
];

const MobileMenu = () => {

  const { pathname } = useLocation();

  return (
    <div className={styles.mobileMenuWrap}>
      <div className={styles.menuList}>
        {navLinks?.map((item, index) => (
          <NavLink 
            key={index} 
            to={item?.url} 
            className={cn(styles.link, {[styles.active]: pathname === item.url})}
          >
            <Image
              className={styles.pic}
              src={item?.image}
              srcDark={item?.imageDark}
              alt={item?.title}
            />
            <Image
              className={styles.activeImg}
              src={item?.imageActive}
              srcDark={item?.imageDarkActive}
              alt={item?.title}
            />
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default MobileMenu