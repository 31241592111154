import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const navLinks = [
    {
        title: "Services",
        url: "/services",
    },
    {
        title: "Case Studies",
        url: "/case-studies",
    },
    {
        title: "Blogs",
        url: "/blogs",
    },
    {
        title: "Careers",
        url: "/careers",
    },
    {
        title: "About Us",
        url: "/about-us",
    },
];

const Headers = ({containerClass}) => {

    const [visibleNav, setVisibleNav] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [showScrolling, setShowScrolling] = useState(false);
    const [showReveal, setShowReveal] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.scrollY;
          //console.log('Scroll Top Value => ', scrollTop);
          setIsSticky(scrollTop > 150);
          setShowScrolling(scrollTop > 0)
          setShowReveal(scrollTop > 300)
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={cn(styles.header, showScrolling ? styles.scrolling : "", showReveal ? styles.revealHeader : "" )}>
            <div className={cn(containerClass ? containerClass : 'container', styles.container)}>
                <Link
                    className={styles.logo}
                    to="/"
                    onClick={() => setVisibleNav(false)}
                >
                    <Image
                        className={styles.pic}
                        src="/images/logo-dark.svg"
                        srcDark="/images/logo-light.svg"
                        alt="Uitoux Solutions"
                    />
                </Link>
                <div
                    className={cn(styles.wrap, { [styles.active]: visibleNav })}
                >
                    <nav className={styles.nav}>
                        {navLinks.map((x, index) =>
                            x.content ? (
                                <DropdownMenu
                                    className={styles.group}
                                    item={x}
                                    key={index}
                                    setValue={setVisibleNav}
                                />
                            ) : (
                                <NavLink
                                    className={cn(styles.link, {
                                        [styles.active]: pathname === x.url,
                                    })}
                                    to={x.url}
                                    key={index}
                                    onClick={() => setVisibleNav(false)}
                                >
                                    {x.title}
                                </NavLink>
                            )
                        )}
                    </nav>
                    {/* <div className={styles.details}>
                        <div className={styles.contact}>
                            {contact.map((x, index) => (
                                <div className={styles.element} key={index}>
                                    <div className={styles.category}>
                                        {x.title}
                                    </div>
                                    <div className={styles.text}>
                                        {x.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.socials}>
                            {socials.map((x, index) => (
                                <a
                                    className={styles.social}
                                    href={x.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <Icon name={x.title} size={x.size} />
                                </a>
                            ))}
                        </div>
                        <Link
                            className={cn(
                                "button-stroke button-small",
                                styles.button
                            )}
                            to="/"
                        >
                            Get free trial
                        </Link>
                    </div> */}
                </div>
                <Link
                    className={cn(styles.button, styles.linkButton)}
                    to="/book-appointment"
                    //target='_blank'
                >
                    <span>Get in Touch</span>
                    <span className={styles.linkButtonIcon}>
                        <img src="/images/content/arrow-right-white.svg" alt="Get in Touch" />
                    </span>
                </Link>
                <button
                    className={cn(styles.burger, {
                        [styles.active]: visibleNav,
                    })}
                    onClick={() => setVisibleNav(!visibleNav)}
                ></button>
            </div>
        </header>
    );
};

export default Headers;
